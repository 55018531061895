import './app.scss';
import 'themes/global.scss';
import '/node_modules/font-awesome/scss/font-awesome.scss';
import { AuthenticateStep, AuthService, FetchConfig } from 'aurelia-authentication';
import { HttpClient } from 'aurelia-fetch-client';
import { PLATFORM } from 'aurelia-pal';
import { NavigationInstruction, PipelineResult, Router, RouterConfiguration, RouterEvent } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { EventAggregator } from 'aurelia-event-aggregator';
import { log } from 'services/logger/log';
import { AppSettings } from 'config/appsettings';
import { authConfig } from 'config/plugins/authentication';

@autoinject
export class App {
    public router: Router;

    constructor(private authService: AuthService, private fetchConfig: FetchConfig, private httpClient: HttpClient, private i18n: I18N, private eventAggregator: EventAggregator) {
        eventAggregator.subscribe(RouterEvent.Error, (event: { instruction: NavigationInstruction; result: PipelineResult }) => {
            log.error('Navigation failed', event.result.output, event.instruction);
        });

    }

    public activate() {
        this.fetchConfig.configure(this.httpClient);
        this.i18n.setLocale('no').then(() => log.debug('Language configured.'));
    }

    public configureRouter(config: RouterConfiguration, router: Router) {

        config.title = 'Datalast';

        config.addAuthorizeStep(AuthenticateStep); // Add a route filter so only authenticated uses are authorized to access some routes
        config.options.pushState = true;


        config.map([
            {
                route: ['', '/index'], name: 'index',
                moduleId: PLATFORM.moduleName('routes/home/home'),
                nav: true, title: 'Home'
            }
        ]);

        var handleUnknownRoutes = (instruction: any) => {
            log.warn('Unknown route - logged in', instruction);
            return PLATFORM.moduleName('routes/not-found/not-found');
        };

        config.mapUnknownRoutes(handleUnknownRoutes);
        config.fallbackRoute('errorpage');

        this.router = router;
    }
}