import { Aurelia } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { configureAuth } from 'config/plugins/authentication';
import { configureMaps } from 'config/plugins/google-maps';
import { AuthService } from 'aurelia-authentication';
import { LogManager } from 'aurelia-framework';
import * as Bluebird from 'bluebird';
import moment from 'moment';
import { Config } from 'aurelia-api';
import { CalendarHelper } from 'lib/calendarHelper';
import { I18N } from 'aurelia-i18n';
import { AppSettings } from 'config/appsettings';
import { AppLogAppender } from 'services/logger/app-log-appender';
import { configureApplicationInsights } from 'config/plugins/app-insights';

var resBundle = require('i18next-resource-store-loader?include=\\.json$!./locales/index.js');

Bluebird.config({
    warnings: false,
    longStackTraces: false
});

export async function configure(aurelia: Aurelia) {
    //Logging
    configureApplicationInsights();
    LogManager.addAppender(new AppLogAppender());
    LogManager.setLevel(AppSettings.appLogLevel);

    if (!AppSettings.isProduction) {
        // eslint-disable-next-line no-console
        console.info('Development logging is enabled!');
        aurelia.use.developmentLogging();
    }

    aurelia.use.standardConfiguration();

    aurelia.use.plugin(PLATFORM.moduleName('aurelia-api'), (config: Config) => {
        config.registerEndpoint('auth', AppSettings.endpoints.auth);
        config.registerEndpoint('api', AppSettings.endpoints.api);
    });
    //configureAuth(aurelia);

    aurelia.use.plugin(PLATFORM.moduleName('aurelia-i18n'), (i18n: I18N) => {
        var result = i18n.setup({
            resources: resBundle,
            lng: 'nb-NO',
            attributes: ['t'],
            fallbackLng: 'nb-NO',
            debug: false
        });

        //Configure moment
        moment.locale('no', {
            months: CalendarHelper.getMonthNames(i18n),
            monthsShort: CalendarHelper.getMonthNamesShort(i18n),
            weekdays: CalendarHelper.getWeekNames(i18n),
            weekdaysShort: CalendarHelper.getWeekNamesShort(i18n),
            calendar: CalendarHelper.getCalendarTexts(i18n),
            relativeTime: CalendarHelper.getCalendarTextsRelative(i18n)
        });

        return result;
    });

    aurelia.use.feature(PLATFORM.moduleName('resources/index'));
    aurelia.use.plugin(PLATFORM.moduleName('config/plugins/material-design'));
    aurelia.use.plugin(PLATFORM.moduleName('aurelia-validation'));
    aurelia.use.plugin(PLATFORM.moduleName('aurelia-ui-virtualization'));

    configureMaps(aurelia);

    //aurelia.use.globalResources(PLATFORM.moduleName(''));

    aurelia.use.plugin(PLATFORM.moduleName('aurelia-dialog'), (config: any) => {
        config.useDefaults();
        config.settings.lock = true;
        config.settings.centerHorizontalOnly = false;
        config.settings.startingZIndex = 5;
        config.settings.keyboard = true;
    });

    aurelia.use
        .globalResources(PLATFORM.moduleName('components/navbar/navbar'))
        .globalResources(PLATFORM.moduleName('components/main-header/main-header'))
        .globalResources(PLATFORM.moduleName('components/vd-charts/vd-charts/vd-charts'))
        .globalResources(PLATFORM.moduleName('components/vd-charts/vd-piechart/vd-piechart'))
        ;

    configureAuth(aurelia);

    await aurelia.start();

    let authService = aurelia.container.get(AuthService);

    aurelia.setRoot(PLATFORM.moduleName('routes/app/app'));
}
