import { Aurelia } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { Configure } from 'aurelia-google-maps';

export const configureMaps = (aurelia: Aurelia) => {
    aurelia.use.plugin(PLATFORM.moduleName('aurelia-google-maps'),
        (config: Configure) => {
            config.options({
                apiKey: 'AIzaSyBoTUyGR_IQPAY77CdUkeqiZq-NnX6pjPY',
                clientId: 'Linx',
                apiLibraries: 'drawing,geometry',
                apiScript: 'https://maps.googleapis.com/maps/api/js',
                options: {},
                markerCluster: {
                    enable: true,
                    src: '/scripts/marker-clusterer/markerclusterer.js?v=2',
                    imagePath: '/images/marker-clusterer/m',
                    imageExtension: 'png'
                }
            });
        });
};
