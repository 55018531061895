import './navbar.scss';

export class Navbar {
    public isShowing: boolean;
    constructor() {}

    public async attached() {}

    public toggle() {
        this.isShowing = !this.isShowing;
    }
}
